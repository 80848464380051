import { Toaster } from 'react-hot-toast';
import './App.css';
import AppRouter from './layouts/RouterLayout';
import Popup from './components/Popup/Popup';

function App() {
  return (
    <>
      <Toaster position='top-right' />
      <AppRouter />
      <Popup />
    </>
  );
}

export default App;
