import React, { useState } from 'react';
import './RightUser.css';
import Header from '../../../components/Header/Header';
import Icon from '../../../components/Icon/Icon';
import Upload from '../../../components/Upload/Upload';
import { useForm } from 'react-hook-form';
import {
  createClient,
  getClient,
  updateClient,
} from '../../../utils/api/client';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

function RightUser({}) {
  const [ShowEdit, setShowEdit] = useState(true);
  const { id, action } = useParams();
  const { data: user } = useQuery(
    `client-${id}`,
    id ? () => getClient(id) : {}
  );
  const { data: profile } = useQuery(`profile`);
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      owner: profile?._id,
    },
    values: {
      ...user,
    },
  });

  const addVisit = (data) => {
    console.log({ data });
    if (action === 'ajouter')
      createClient({ data })
        .then((data) => {
          // setShowAvatar(false);
          setShowEdit((e) => !e);
        })
        .catch((err) => {});
    else
      updateClient({ data })
        .then((data) => {
          // setShowAvatar(false);
          setShowEdit((e) => !e);
        })
        .catch((err) => {});
  };

  return (
    <div className='RightUser'>
      <Link to='/clients' className='overlay'>
        {/* <div style={{display:'flex'}} ></div> */}
      </Link>
      <Header />
      <form onSubmit={handleSubmit(addVisit)} className='profile'>
        <h2>
          Profile du client{' '}
          <Link to='/clients'>
            <button type='button'>
              <Icon name='cross' />
            </button>
          </Link>
        </h2>
        {action && action !== 'view' ? (
          <div>
            <Upload
              onUpload={(url) => setValue('profileImg', url)}
              src={watch('profileImg')}
            />

            <div
              className=''
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                margin: ' 30px 0',
              }}
            >
              <div className='input-container'>
                <label htmlFor=''>Nom et Prénom</label>
                <input
                  type='text'
                  {...register('nom')}
                  placeholder='tapez le nom et prénom'
                />
              </div>
              {/* <div className='input-container'>
                <label htmlFor=''>Prénom</label>
                <input
                  type='text'
                  {...register('prénom')}
                  placeholder='tapez le prénom'
                />
              </div> */}
              <div className='input-container'>
                <label htmlFor=''>Email</label>
                <input
                  type='text'
                  {...register('email')}
                  placeholder="tapez l'adresse email"
                />
              </div>
              <div className='input-container'>
                <label htmlFor=''>Téléphone</label>
                <input
                  type='text'
                  {...register('telephone')}
                  placeholder='tapez le téléphone'
                />
              </div>
              <div className='input-container'>
                <label htmlFor=''>Adresse</label>
                <input
                  type='text'
                  {...register('address')}
                  placeholder="tapez l'adresse"
                />
              </div>
              <div className='input-container'>
                <label htmlFor=''>Genre</label>
                <select {...register('gender')}>
                  <option value='m'>Masculin</option>
                  <option value='f'>Feminin</option>
                </select>
              </div>
              {/* <SelectProprieté
                {...{
                  register,
                  errors:null,
                  profile:user,
                  watch,
                  setValue,
                  lable:'Proprieté',
                  name:'unité',
                }}
              /> */}
              <div className='input-container'>
                <label htmlFor=''>Dernier achat </label>
                <input
                  type='date'
                  {...register('date')}
                  placeholder='tapez le date de la Dernier achat'
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img
              src={user?.profileImg || '/images/users/profile.jpeg'}
              alt=''
            />
            <h3>{user?.nom}</h3>
            <div className='line'></div>
            <h2>Informations</h2>
            <p className='info'>
              <Icon name='envelope' />
              {user?.email}
            </p>
            <div className='line'></div>
            <p className='info'>
              <Icon name='phone-call' />
              {user?.telephone}
            </p>
            <div className='line'></div>
            <p className='info'>
              <Icon name='marker' />
              {user?.address}
            </p>

            <div className='last-meet'>
              <div className='video'>
                <Icon name='video-camera' />
              </div>
              <div>
                <p>Dernière commandes</p>
                <span>{user?.date}</span>
              </div>
            </div>
          </div>
        )}
        <button className='add'>{ShowEdit ? 'Enregistrer' : 'Modifier'}</button>
      </form>
    </div>
  );
}

export default RightUser;
