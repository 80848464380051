import React, { useRef } from 'react';
import { useState } from 'react';
import Icon from '../../Icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCapture,
  getCapture,
  updateCapture,
} from '../../utils/api/capture';
import { useForm } from 'react-hook-form';
import Upload from '../../components/Upload/Upload';

function AddCaptureService({ setShow }) {
  const router = useNavigate();
  const { data: profile } = useQuery('profile');
  const { id } = useParams();

  const { data: capture } = useQuery('capture', () =>
    id !== 'ajouter' ? getCapture(id) : {}
  );

  const CreateCapture = ({ url, name }) => {
    (id === 'ajouter' ? createCapture : updateCapture)({
      url,
      name,
      user: profile?._id,
      id: capture?._id,
    })
      .then((data) => {
        // router(`/capture_service/${data?._id}/google`)
      })
      .catch((err) => console.log(err));
  };

  const { register, handleSubmit, watch, setValue } = useForm({
    values: capture,
  });

  return (
    <form onSubmit={handleSubmit(CreateCapture)} className='AddCapture'>
      <Link
        to={'/capture_service'}
        className='overlay'
        onClick={() => setShow(false)}
      ></Link>
      <div className='add-cap'>
        <p>Insérez le Nom</p>
        <input
          type='text'
          {...register('name')}
          placeholder='Tapez le nom'
          id=''
        />
        <p>Insérez le lien</p>
        <input
          type='text'
          {...register('url')}
          placeholder='Tapez le lien'
          id=''
        />
        <p>où</p>

        <button>
          <Icon name='import-visit' />
          {!watch('url')
            ? 'Importer la visite'
            : 'fichier téléchargé avec succès'}
          <p id={`count-google`} style={{ margin: 0 }}></p>
          <Upload
            file_id='google'
            onUpload={(url) => setValue('url', url)}
            onStartUpload={(id, name, size, type) => setValue('url', '')}
            isImage={false}
          />
        </button>
        {/* <span>Importer les fichiers de type : ZIP</span> */}
        <p>Visualisation</p>
        <iframe src={watch('url')} frameborder='0'></iframe>
      </div>

      <button disabled={!watch('url') || !watch('name')}>
        <Icon name='checkbox' />
        Enregistrer
      </button>
    </form>
  );
}

export default AddCaptureService;
