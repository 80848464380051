import React from 'react';
import './UserCounts.css';
import { useQuery } from 'react-query';
import { getUser, getUsers } from '../../utils/api/users';
import SemiCircleChart from './SemiCircleChart/SemiCircleChart';
import VentesAnnuelles from './VentesAnnuelles/VentesAnnuelles';
import { useParams } from 'react-router-dom';

function UserCounts({ dashboard = {} }) {
  //   const { data: user } = useQuery("users");
  const { data: users, error, isLoadingUsers } = useQuery('users', getUsers);
  const { id } = useParams();

  const { data: user } = useQuery(`user-${id}`, () => getUser({ id }));

  return (
    <div className='UserCounts chart'>
      <div className='avatar'>
        <img
          loading='lazy'
          src={user?.profilePicture || '/images/user.png'}
          alt=''
        />
        <div>
          <h5>{`${user?.first_name} ${user?.last_name}`}</h5>
          <p>TechnoCommercial</p>
        </div>
      </div>
      <div className='line'></div>
      <div className='item'>
        <h1> {dashboard?.Revenu / 1000}K </h1>
        <div className='flex'>
          {/* <span>+10%</span> */}
          <span>MAD</span>
        </div>
      </div>
      <div className='line'></div>
      <SemiCircleChart />
      <div className='line'></div>
      <VentesAnnuelles dashboard={dashboard} />
    </div>
  );
}

export default UserCounts;
