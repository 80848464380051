import React, { Fragment, useEffect, useState } from 'react';
import './Dashboard.css';
import Counts from '../../components/Dashboard/Counts/Counts';
import Chart from '../../components/Dashboard/Chart/Chart';
import { useQuery, useQueryClient } from 'react-query';
import ChartBar from '../../components/Dashboard/Chart/ChartBar';
import UserCounts from '../../components/Dashboard/UserCounts';
import SelectUser from '../../components/SelectUsers/SelectUser';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../utils/api/users';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { getDashboard, getDashboardTechno } from '../../utils/api/dashboard';
function Dashboard() {
  const { data: profile } = useQuery('profile');
  const { id } = useParams();
  const { data: user } = useQuery(`user-${id}`, () => getUser({ id }));
  const { data: dashboard, isLoading: isLoadingDash } = useQuery(
    `dashboard-${id}`,
    () => getDashboardTechno(id)
  );
  const router = useNavigate();
  const { register, errors, watch, setValue } = useForm({
    defaultValues: { user: id },
  });

  useEffect(() => {
    queryClient.invalidateQueries('techno-user');
  }, [id]);

  useEffect(() => {
    router(`/dashboard/${watch('user')}`);
    return () => {};
  }, [watch('user')]);

  return (
    <div className='Dashboard '>
      <SelectUser
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: '',
          name: 'user',
        }}
      />
      <UserCounts dashboard={dashboard} user={user} />
      <div className='grid-dash'>
        <Counts dashboard={dashboard} user={user} />
        {/* <Chart dashboard={dashboard} user={user} /> */}
        <ChartBar dashboard={dashboard} user={user} />
      </div>
    </div>
  );
}

export default Dashboard;
