import React, { useState } from 'react';
import './Authentification.css';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import AuthInfo from '../../components/auth_info/AuthInfo';

import { login } from '../../utils/api/auth';

function Login() {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: { role: 'Admin' },
  });
  const [ShowPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([
    {
      id: '1',
      nom: 'Technicien',
    },
    {
      id: '2',
      nom: 'Propriétaire',
    },
    {
      id: '3',
      nom: 'Locataire',
    },
    {
      id: '0',
      nom: 'Admin',
    },
  ]);

  return (
    <div className='Login'>
      <AuthInfo />
      <div className='Form-container'>
        <form onSubmit={handleSubmit(login)}>
          <div className='form-items'>
            <h2>Se Connecter</h2>
            <div className='inputs'>
              <div className='input-container'>
                <label htmlFor=''>Email</label>
                <input
                  {...register('email')}
                  required
                  type='email'
                  placeholder='Entrer votre email'
                />
              </div>
              <div className='input-container'>
                <div className='flex'>
                  <label htmlFor=''>Password</label> <span> Oublié？</span>
                </div>
                <input
                  {...register('password')}
                  required
                  type={ShowPassword ? 'text' : 'password'}
                  placeholder='Entrer votre mot de passe'
                />
                <div
                  className='show-password'
                  onClick={() => setShowPassword((v) => !v)}
                >
                  <Icon name={ShowPassword ? 'hide-eye' : 'eye'} />
                </div>
              </div>
            </div>
            <div className='buttons'>
              <button className='primary-color '>Se Connecter</button>
              {/* <button className="primary-color">
                <Icon name="google" />
                Continuer avec Google
              </button>
              <button className="primary-color">
                <Icon name="facebook" />
                Continuer avec Facebook
              </button> */}
              <h4>
                Vous n’avez pas de compte ?
                <Link  to={'register'}>
                  Créer un compte
                </Link>
              </h4>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
