import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Table from '../components/Table/Table';
import AppLayout from '../layouts/AppLayout';
import { usersRouter } from '../layouts/Routes/usersRouter';
import { agendaRouter } from '../layouts/Routes/agendaRouter';
import { clientsRouter } from '../layouts/Routes/clientsRouter';
import { captureRouter } from '../layouts/Routes/captureRouter';
import { reportingRouter } from '../layouts/Routes/reportingRouter';
import { dashboardRouter } from '../layouts/Routes/dashboardRouter';
import { lazy } from 'react';

// const chatRouter = lazy(() => import('../layouts/Routes/chatRouter'));
import { chatRouter } from '../layouts/Routes/chatRouter';

const adminRoutes = [
  {
    link: '/',
    icon: 'apps-add',
    name: 'Surveillance d’activités',
    test: '',
    route: usersRouter,
  },
  {
    link: '/agenda',
    icon: 'calendar',
    name: 'Agenda',
    route: agendaRouter,
  },
  {
    link: '/capture_service',
    icon: 'video-camera',
    name: 'Capture &  Visites 360',
    route: captureRouter,
  },
  {
    link: '/clients',
    icon: 'user',
    name: 'Gestion des clients',
    route: clientsRouter,
  },
  {
    link: '/reporting',
    icon: 'folder',
    name: 'Reporting',
    route: reportingRouter,
  },
  {
    link: '/chat',
    icon: 'bell-ring',
    name: 'Communication interne',
    route: chatRouter,
  },
];

const technicienRoutes = [
  {
    link: '/',
    icon: 'grid',
    name: 'Vue d’ensemble',
    route: dashboardRouter,
  },
  {
    link: '/agenda',
    icon: 'calendar',
    name: 'Agenda',
    route: agendaRouter,
  },
  {
    link: '/capture_service',
    icon: 'video-camera',
    name: 'Capture &  Visites 360',
    route: captureRouter,
  },
  {
    link: '/clients',
    icon: 'user',
    name: 'Gestion des clients',
    route: clientsRouter,
  },
  {
    link: '/reporting',
    icon: 'folder',
    name: 'Reporting',
    route: reportingRouter,
  },
  {
    link: '/chat',
    icon: 'bell-ring',
    name: 'Communication interne',
    route: chatRouter,
  },
];

const getRoleRoutes = (user) => {
  let router = [];

  switch (user?.role) {
    case 'Admin':
      router = adminRoutes;

      break;

    case 'Technicien':
      router = technicienRoutes;
      break;
  }

  return { router };
};

const Roles = {
  Admin: 'admin',
  Propriétaire: 'owner',
  Locataire: 'renter',
  Technicien: 'techno',
};

export { getRoleRoutes, Roles, adminRoutes };
