import React, { useState } from 'react';
import Icon from '../../components/Icon/Icon';
import './CaptureService.css';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import Table from '../../components/Table/Table';
import AddCaptureService from './AddCaptureService';
import { deleteCapture, getAllCaptures } from '../../utils/api/capture';
import AddGoogleStreet from './AddGoogleStreet';
import { getlistVideos } from '../../utils/api/googleStreet';
import moment from 'moment';

function CaptureService() {
  const [ShowAvatar, setShowAvatar] = useState(false);
  const [SelectedAvatar, setSelectedAvatar] = useState(null);

  const keys = [
    'Visite Effectués',
    'Effectuée par',
    'Date',
    'Google Street',
    'Télécharger',
  ];
  const {
    data: captures,
    error,
    isLoading,
  } = useQuery('captures', getAllCaptures);

  const { data: user } = useQuery('profile');
  const { id, action } = useParams();
  const [Show, setShow] = useState(false);
  const {
    data: listVideos,
    error: errListVideos,
    isLoading: isLoadingListVideos,
  } = useQuery('ListVideos', getlistVideos);

  const data = {
    name: 'Restaurant Le Cabestan',
    user: 'Saad alnit',
    date: '03-06-2024',
    button: 'Télécharger',
  };

  const getVideoStatus = (item) => {
    let video = listVideos?.photoSequences?.find(
      (v) => v?.name === item?.google_id
    );

    if (video?.error) return video?.error?.message;
    else if (video && !video?.done) return 'PROCESSING';
    else if (video?.done) return 'SUCCESS';
  };

  return (
    <div className='Avatar'>
      {/* <button type='button' className='add' onClick={() => setShowAvatar(true)}>
        <Icon name={'add-cercle'} /> 
      </button> */}
      {id && !action && <AddCaptureService setShow={setShow} />}
      {/* {action && <AddGoogleStreet setShow={setShow} />} */}

      <Table
        keys={keys}
        isLoading={isLoading}
        list={captures}
        // show_action={user?.role === "Admin"}
        button={'Importer une visite'}
        url='capture_service'
        design={true}
        show_search={false}
      >
        {({ List }) =>
          List?.map((item, index) => (
            <tr key={index}>
              <td>
                <div className='img'>
                  <Icon name='video-camera' />

                  {item?.name}
                </div>
              </td>
              <td>
                <div>
                  {item?.user?.first_name} {item?.user?.last_name}
                </div>
              </td>
              <td>
                <div>{moment(item?.createdAt).format('DD/MM/YYYY')}</div>
              </td>
              <td>
                <div>
                  <p
                    style={{
                      maxWidth: 140,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {getVideoStatus(item)}
                  </p>
                </div>
              </td>
              <td>
                {' '}
                <div>
                  <button
                    onClick={() =>
                      navigator.clipboard
                        .writeText(item?.url)
                        .then(() => {
                          console.log('Text copied to clipboard');
                        })
                        .catch((err) => {
                          console.error('Failed to copy text: ', err);
                        })
                    }
                  >
                    Copier{' '}
                  </button>
                </div>
              </td>
              {
                <td>
                  <div className='flex actions'>
                    <button
                      style={{ background: '#0000' }}
                      onClick={() => deleteCapture(item?._id)}
                    >
                      {' '}
                      <Icon name='trash' />{' '}
                    </button>

                    <Link
                      style={{ background: '#0000' }}
                      to={`/capture_service/${item?._id}`}
                    >
                      <Icon name='eye' />
                    </Link>
                  </div>
                </td>
              }
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default CaptureService;
