import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Notifications.css';
import { useQuery } from 'react-query';
import { confirmNotification, deleteNotification } from '../../utils/api/users';
import moment from 'moment';
import { useChat } from '../../hooks/useChat';

function Notifications() {
  const [Show, setShow] = useState(false);
  const { data: profile } = useQuery('profile');
  const {} = useChat();

  const last_visit = localStorage.getItem('last_visit');

  const notification =
    profile?.notification?.length > 0
      ? [...profile?.notification]?.reverse()
      : null;

  return (
    <div className={`Notifications `}>
      <button onClick={() => setShow(true)}>
        <Icon name='bell' />
        {notification?.length && <span>{notification?.length + 1}</span>}
      </button>
      {Show && (
        <>
          <div className='overlay' onClick={() => setShow(false)}></div>
          <div className='list'>
            {notification ? (
              notification?.map((p, i) => (
                <div className='item' key={i}>
                  {p?.img && (
                    <img
                      height={30}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: 'solid 1px #eee',
                      }}
                      width={30}
                      src={p?.img}
                      alt=''
                      srcset=''
                    />
                  )}
                  <div>
                    {p?.message} <br />
                    <span>{p?.body}</span>
                    <p>{moment(p?.date)?.format('DD/MM/YYYY hh:mm')}</p>
                  </div>
                  <button
                    className=''
                    style={{ color: 'red', fontSize: 20, display: 'flex' }}
                    onClick={() =>
                      deleteNotification(p?.operationId || p?._id).catch(
                        (err) => console.log(err)
                      )
                    }
                  >
                    <Icon name='trash' />
                  </button>
                </div>
              ))
            ) : (
              <div className='item'>
                <div>
                  Notifications
                  <p>Tu n'as aucune notification.</p>
                </div>
                <button onClick={() => setShow(false)}>
                  <Icon name='close' />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Notifications;
