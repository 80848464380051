import toast from 'react-hot-toast';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';
const login = async (data) => {
  Axios.post('/auth/signin', data)
    .then(({ data }) => {
      const { token, user } = data;
      console.log(token, user);
      if (user && token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('token');
      }
    })
    .catch((err) => {});
};

const registerUser = async (data) => {
  if (data?.password !== data?.confirm_password)
    return toast.error(
      'le mot de passe et la confirmation du mot de passe ne correspondent pas'
    );

  Axios.post('/auth/signup', data)
    .then(({ data }) => {
      const { token, user } = data;
      console.log(token, user);
      if (user && token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('token');
      }
    })
    .catch((err) => {});
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  queryClient.invalidateQueries('token');
  queryClient.invalidateQueries('profile');
  queryClient.clear();
};

export { login, registerUser, logout };
