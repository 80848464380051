import cardValidator from 'card-validator';
import { z } from 'zod';
import {
  isValidCVV,
  isValidCardNumber,
  isValidExpiryDate,
  isValidName,
} from './form_valid';

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

const PropertySchema = z
  .object({
    unité: z.string().min(3),
    vrUrl: z.string().url(),
    images: z.array(z.string()).min(1),
    conditions: z.array(z.string()),
    équipement: z.array(z.string()),
    owner: z.string().min(1),
    description: z.string().min(10),
    region: z.string().min(1),
    country: z.string().min(1),
    code_postal: z.number().min(1),
    address: z.string().min(10),
    price: z.number().min(1),
    durée: z.number().min(1),
    Acompte: z.number().min(10),
    latitude: z.number(),
    longitude: z.number(),
  })
  .refine((data) => data?.password === data?.confirmPassword, {
    message: '',
    path: ['confirmPassword'], // path of error
  });

const UserSchema = z.object({
  profilePicture: z.string().optional(),
  first_name: z.string().min(3),
  last_name: z.string().min(3),
  username: z.string().min(3),
  email: z.string().email().min(3),
  role: z.string().min(3),
  address: z.string().min(3),
  sexe: z.string().min(3),
  Nationalité: z.string().min(3),
  status: z.boolean(),
  CIN: z.string().min(3),
  phone: z.string().regex(phoneRegex, 'Invalid Phone Number!'),
  // IdentityUrls: z.array(z.string()),
});

const PaymentSchema = z
  .object({
    object: z.string().min(1),
    montant: z.number(),
    beneficiare: z.string().min(1),
    // first_name: z.string().min(1),
    // last_name: z.string().min(1),
    payment_method: z.string().min(1),
    user_card_name: z.string().optional(),
    exp_date: z.string().optional(),
    card_number: z.string().optional(),
    card_cvv: z.string().optional(),
    unité: z.string().optional(),
  })
  .refine(
    (data) =>
      data?.payment_method !== 'card' || isValidName(data.user_card_name),
    {
      message: 'required',
      path: ['user_card_name'], // path of error
    }
  )
  .refine(
    (data) =>
      data?.payment_method !== 'card' || isValidExpiryDate(data.exp_date),
    {
      message: 'required',
      path: ['exp_date'], // path of error
    }
  )
  .refine(
    (data) =>
      data?.payment_method !== 'card' || isValidCardNumber(data.card_number),
    {
      message: 'required',
      path: ['card_number'], // path of error
    }
  )
  .refine(
    (data) => data?.payment_method !== 'card' || isValidCVV(data.card_cvv),
    {
      message: 'required',
      path: ['card_cvv'], // path of error
    }
  )
  .refine((data) => data?.object !== 'proprietaire' || !!data?.unité, {
    message: 'required',
    path: ['unité'], // path of error
  });

const ContraDeBailSchema = z
  .object({
    adresse_du_locataire: z.string().min(3),
    locataire_first_name: z.string().min(3),
    locataire_last_name: z.string().min(3),
    unité: z.string().min(3),
  })
  .passthrough();

const FicheSchema = z
  .object({
    technicien: z.string().min(3),
    date: z.string().min(3),
    description: z.string().min(3),
    unité: z.string().min(3),
  })
  .passthrough();

const CaptureSchema = z
  .object({
    pays: z.string().min(3),
    ville: z.string().min(3),
    address: z.string().min(3),
    lien: z.string().min(3),
    numerisation: z.string().min(3),
    nom_capturer: z.string().min(3),
    superficie: z.number(),
    unite_mesure: z.string(),
    images: z.array(z.string()).min(1),
    date: z.date().optional(),
    time: z.string().optional(),
    proprietaire: z.string().optional(),
    name: z.string().optional(),
    email: z.string().optional(),
    accuellir_le_technicien: z.string().optional(),
    details_for_technicien: z.string().optional(),
  })
  .passthrough()
  .refine((data) => Number(data?.tab) === 0 || !!data?.date, {
    message: 'required',
    path: ['date'], // path of error
  })
  .refine((data) => Number(data?.tab) === 0 || !!data?.time, {
    message: 'required',
    path: ['time'], // path of error
  })
  .refine((data) => Number(data?.tab) < 2 || !!data?.name, {
    message: 'required',
    path: ['name'], // path of error
  })
  .refine((data) => Number(data?.tab) < 2 || !!data?.email, {
    message: 'required',
    path: ['email'], // path of error
  });

const ReportingSchema = z
  .object({
    title: z.string().min(3),
    description: z.string().min(3),
    startDate: z.string().min(3),
    endDate: z.string().min(3),
    objectif: z.string().min(3),
    donnes: z.array(z.string()),
    commentaire: z.string().min(3),
    user: z.string().min(3),
    user_name: z.string().min(3),
  })
  .passthrough();

export {
  PropertySchema,
  UserSchema,
  PaymentSchema,
  ContraDeBailSchema,
  FicheSchema,
  CaptureSchema,
  ReportingSchema,
};
